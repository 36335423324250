import './App.css';
import Lottie from 'lottie-web'
import React, {useEffect,useCallback, useState} from 'react'
import {Modal, Div, Card, Button, Flex, Text, GlobeSvg} from './components'
import IconsList from './IconsList'
import Footer from './Footer'
import Frame from './components/Frame'
import { getLocale } from './data';

let lottie = null

function App() {
    const halfHeight = React.useRef(null);
    const prevScrollPos = React.useRef(null);

    const scrollListener = useCallback(() => {
        window.requestAnimationFrame(() => {
            const { pageYOffset } = window
            const delta = pageYOffset - prevScrollPos.current

            if ((Math.abs(delta) < 3) || (!halfHeight.current || pageYOffset < halfHeight.current))
                return prevScrollPos.current = pageYOffset

            if (pageYOffset < 1000) {
                lottie && lottie.goToAndStop(Math.round((pageYOffset - halfHeight.current) / (Math.round(600 / 121))), true)
            }
            prevScrollPos.current = pageYOffset
        })
    }, [])

    const [videoVisible, toggleVideoVisibility] = useState(false)
    const [videoSrc, setVideoSrc] = useState('')
    const [headHeight, setHeadHeight] = useState(0)

    const handleVideoToggle = (videoLink) => {
        if (window.innerWidth < 935) {
            window.open(videoLink, '_blank');     
        } else {
            if (videoVisible) {
                setVideoSrc('')
            } else {
                setVideoSrc(videoLink)
            }
            toggleVideoVisibility(!videoVisible)
        }
    }

    const resizeListener = useCallback(() =>{
        halfHeight.current = (window.innerWidth > 935 ? 1200 : 900) - window.innerHeight
    },[])

    const addListeners = useCallback(() => {
        window.addEventListener('scroll', scrollListener)
        window.addEventListener('resize', resizeListener)
    }, [scrollListener, resizeListener])

    const removeListeners = useCallback(() => {
        window.removeEventListener('scroll', scrollListener)
        window.removeEventListener('resize', resizeListener)
    },[scrollListener, resizeListener])

    const [frameToggled, toggleFrame] = useState(false)
    const [interviewLink, setInterviewLink] = useState(false)
    const handleToggle = (interviewLink) => {
        if (window.innerWidth < 935)
        {
            window.open(interviewLink, '_blank');
        } else {
            if (videoVisible) {
                setInterviewLink('')
            } else {
                setInterviewLink(interviewLink)
            }
            toggleFrame(!frameToggled)
        }
    }

    const locale = getLocale();

    useEffect(() => {
        const head = document.getElementById('head')
        if (head) {
            const height = head.offsetHeight
            setHeadHeight(height)
        }
    }, [])

    useEffect(() => {
        if(!lottie){
                lottie = window.innerWidth < 935 ? Lottie.loadAnimation({
                    container: document.getElementById('lottieAnimationMobile'),
                    renderer: 'svg',
                    loop: true,
                    autoplay: false,
                    path: 'animation_mobile.json',
                    rendererSettings : {
                        className: 'lottie',
                    }
                }) : Lottie.loadAnimation({
                    container: document.getElementById('lottieAnimation'),
                    renderer: 'svg',
                    loop: true,
                    autoplay: false,
                    path: 'animation_mobile.json',
                    rendererSettings : {
                        className: 'lottie',
                    }
                });
                lottie.addEventListener('data_ready', ()=>{
                    const scrollTop = window.scrollY
                    halfHeight.current = (window.innerWidth > 935 ? 1200 : 900) - window.innerHeight
                    if (scrollTop > 1000) {
                        lottie.goToAndStop(121, true)
                    }
                    else if(scrollTop > halfHeight.current) {
                        lottie.goToAndStop(Math.round((scrollTop - halfHeight.current) / (Math.round(600 / 121))), true)
                    } 
                    addListeners()
                })
            }
            return ()=> removeListeners()
        },[addListeners, removeListeners])
  return (
      <>
        <Modal visible={videoVisible} videoSrc={videoSrc} onClose={handleVideoToggle} />
        {frameToggled && <Frame onToggle={handleToggle} link={interviewLink} />}
        <Flex width="100%" height='100%' justifyConten="center" flexDirection="column" alignItems="center" style={{
            overflow: 'hidden',
            position: 'relative',
            WebkitMaskImage: '-webkit-radial-gradient(circle, white 100%, black 100%)',
        }}>
        <Flex  maxWidth="1254px" zIndex="99999" width="100%" flexDirection="column" alignItems="center">
            <Div width="100%" maxWidth="910px" height={["87px","105px"]} mb={["55px","45px"]} >
                <IconsList/>
            </Div>
            <Div id='head'>
                {locale === 'ch' ? <Text textAlign='center' display='block' fontSize={["36px", '100px']} style={{wordBreak: 'break-word'}}>
                普天之下：全<br />球化後的主權<br />和基礎設施
                </Text> : <Text textAlign='center' display='block' fontSize={["36px", "75px"]} style={{wordBreak: 'break-word'}}>
                    ALL UNDER HEAVEN:
                    <br/>
                    S<GlobeSvg/>VEREIGNTY AND
                        <br/>
                    INFRA<wbr />STRUCTURE AFTER
                    <br/>
                    GL<GlobeSvg/>BALIZATION
                </Text>}
                <Text textAlign='center' display='block' fontSize={["36px", locale === 'ch' ? '100px' : "75px"]} pragmatic t>
                    subtitle
                </Text>
            </Div>
        <Flex mx={["16px","auto"]}  mb={['72px', '387px']} mt={["140px","200px"]} maxWidth="860px" flexDirection='column' alignItems="center">
            <Text fontSize={["16px","25px"]}>
                <Text display={"inline !important"} t pragmatic>description1</Text>
            </Text>
            <Text fontSize={["16px","25px"]} pragmatic t>
                description2
            </Text>
            <Button width={["100%","auto"]} mt="25px" onClick={() => handleToggle('https://strelkamag.com/en/article/sovereignty-and-infrastructure-after-globalization')}><Text fontSize={["20px","25px"]} pragmatic t>readEssay</Text></Button>
        </Flex>
            <Card 
                onVideoToggle={handleVideoToggle}
                onToggle={() => handleToggle('https://strelkamag.com/en/article/on-frictionless-sovereignty')}
                background="bishop.png"
                topText="card_bishop.topText"
                name="RYAN BISHOP"
                title="card_bishop.title"
                leftText="card_bishop.leftText"
                rightText="card_bishop.rightText"
                locale={locale}
                videoLink='https://www.youtube.com/embed/g39aobNHboA?autoplay=1'
            />
            <Card 
                onVideoToggle={handleVideoToggle}
                onToggle={() => handleToggle('https://strelkamag.com/en/article/neoliberalism-a-century-of-disappointment')}
                background="slobodyan.png"
                topText="card_slobodian.topText"
                name="Quinn Slobodian"
                title="card_slobodian.title"
                leftText="card_slobodian.leftText"
                rightText="card_slobodian.rightText"
                locale={locale}
                videoLink='https://www.youtube.com/embed/PA_RrgmQCcg?autoplay=1'
            />
            <Card 
                onVideoToggle={handleVideoToggle}
                onToggle={() => handleToggle('https://strelkamag.com/en/article/nomadic-imaginaries-and-chinese-globalization')}
                background="miyu.png"
                topText="card_mi.topText"
                name="You Mi"
                title="card_mi.title"
                leftText="card_mi.leftText"
                rightText="card_mi.rightText"
                locale={locale}
                videoLink='https://www.youtube.com/embed/TwRt3XfU8hw?autoplay=1'
            />
            <Card 
                onVideoToggle={handleVideoToggle}
                onToggle={() => handleToggle('https://strelkamag.com/en/article/the-politics-of-chinese-infrastructure-loans')}
                background="ferchen.png"
                topText="card_ferchen.topText"
                name="Matt Ferchen"
                title="card_ferchen.title"
                leftText="card_ferchen.leftText"
                rightText="card_ferchen.rightText"
                locale={locale}
                videoLink='https://www.youtube.com/embed/4twAEuOOfrw?autoplay=1'
            />
            <Card 
                onToggle={() => handleToggle('https://strelkamag.com/en/article/many-belts-many-roads')}
                background="mok.png"
                topText="card_mok.topText"
                name="Murphy Mok"
                title="card_mok.title"
                leftText="card_mok.leftText"
                rightText="card_mok.rightText"
                locale={locale}
            />
            <Footer/>
        </Flex>
            <Div display={[locale === 'ch' ? "none" : "inherit", "none"]} height={(headHeight - 334 + 105) + "px"} position="absolute" backgroundColor="#7c7373" width="100%" />
            <Div display={["inherit", "none"]} position="absolute" zIndex="-1" marginTop={locale === 'ch' ? '-30px' : (headHeight - 334 + 100) + "px"} top="0" width="2000px" id='lottieAnimationMobile' /> 
            <Div display={["none", "inherit"]} position="absolute" zIndex="-1" marginTop="inherit" top={locale === 'ch' ? '-320px' : "-200px"} width="5000px"  id='lottieAnimation' /> 
        </Flex>
    </>
  );
}
// 430 - wd - 170
// 393 - 
export default App;
