import styled from "@emotion/styled";
import React from 'react';
import {
  typography,
  color,
  layout,
  space,
  position,
  flex,
} from "styled-system";

import I18n from '../data';

const TextTag = styled("span")(
  typography,
  color,
  layout,
  space,
  position,
  flex,
  ({ uppercase, pragmatic }) => ({
    ...(uppercase ? { textTransform: "uppercase" } : null),
    fontFamily: pragmatic
      ? "Pragmatica, system-ui,sans-serif"
      : "Chalet, system-ui,sans-serif",
  })
);

export const Text = function(props) {

  let propsCopy;

  if (props.t) {
    propsCopy = {...props};
    delete propsCopy.children;
  }

  return (
    props.t ? <TextTag
        dangerouslySetInnerHTML={{
            __html: I18n.getTranslation(
              window.location,
              props.children,
              props.args || '',
          ).toString(),
        }}
        {...propsCopy}
    /> : <TextTag
        {...props}
    >
      {props.children}
    </TextTag>
  );
}
