import React from "react";
import { Flex, Text, GlobeSvg, Div, Image } from "./components";
import { getLocale } from "./data";

const team1 = [
  {
    name: "Mikhail Anisimov",
    role: "Production lead / Creative Direction / Research",
    link: "https://mikhailanisimov.com/",
  },
  {
    name: "Yulia Gromova",
    role: "Project Lead / Concept / Research",
    link: "https://www.facebook.com/yulia.gromova.54/",
  },
  {
    name: "Tomas Clavijo",
    role: "Proof of Concept",
    link: "https://raft.haus",
  },
  {
    name: "Katya Sivers",
    role: "Proof of Concept / Research",
    link: "https://www.facebook.com/katya.sivers",
  },
  {
    name: "Andrey Zhileykin",
    role: "Proof of Concept / Research",
    link: "https://www.facebook.com/andrei.zhileikin",
  },
];

const team2 = [
  {
    name: "Lukáš Likavčan,",
    role: "Research Advisor",
    link: "http://likavcan.com",
  },
  {
    name: "Philip Maughan,",
    role: "Editor",
    link: "http://philipmaughan.net",
  },
  {
    name: "Theo Merz,",
    role: "Editor",
  },
  {
    name: "Timur Zolotoev,",
    role: "Editor",
    link: "https://www.instagram.com/timurz/?hl=en",
  },
  {
    name: "Lynsey Free,",
    role: "Proofreader",
  },
  {
    name: "Xiaohui Jiang/ 翻譯：蔣曉暉,",
    role: "Translator",
  },
];

const team3 = [
  {
    name: "Sonya Elterman",
    role: "Supervisor",
  },
  {
    name: "Egor Antonenkov",
    role: "Product Manager",
  },
  {
    name: "Dmitri Gusarov",
    role: "Web Developer",
  },
  {
    name: "Nadezhda Savina",
    role: "Web Developer",
  },
  {
    name: "Kseniya Belaya",
    role: "Designer",
    link: "https://www.behance.net/kseniabela9?fbclid=IwAR259syf7ZGk1q0RVylrlQ72l-PST8LGM0B1sqS7CRyAmZqG__g-C9z4if0",
  },
  {
    name: "Yana Chornaya",
    role: "Transcriber",
  },
  {
    name: "Varvara Dunayevskaya",
    role: "Video Editor",
  },
  {
    name: "Aleksey Krukovsky",
    role: "Video Editor",
    link: "https://www.instagram.com/alexeykrukovsky/",
  },
  {
    name: "Danila Mikhalev",
    role: "Sound Designer",
    link: "https://www.instagram.com/mic_lv/",
  },
];

const expertInput = [
  {
    name: "You Mi",
  },
  {
    name: "Ryan Bishop",
  },
  {
    name: "Quinn Slobodian",
  },
  {
    name: "Matt Ferchen",
  },
  {
    name: "Murphy Mok",
  },
];

const Footer = () => (
  <Flex
    mx={["16px", "auto"]}
    mb="250px"
    flexDirection="column"
    maxWidth="860px"
    mt={["auto", "0"]}
  >
    {getLocale() === "ch" ? (
      <Text
        id="about"
        textAlign="center"
        fontSize={["50px", "100px"]}
        mb={["20px", "35px"]}
      >
        觀看影片
      </Text>
    ) : (
      <Text
        id="about"
        textAlign="center"
        fontSize={["50px", "100px"]}
        mb={["20px", "35px"]}
      >
        AB
        <GlobeSvg ht="70px" htM="36px" />
        UT:
      </Text>
    )}
    <Text fontSize={["20px", "25px"]} mb={["70px", "150px"]}>
      <Text display={["none !important", "inline-block !important"]}>
        &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
      </Text>
      <Text display={"inline !important"} t pragmatic>
        about
      </Text>
    </Text>
    <Text
      textAlign="center"
      fontSize={["50px", "100px"]}
      mb={["15px", "40px"]}
      uppercase
    >
      ©redits
    </Text>
    <Flex flexDirection="column">
      <Text pragmatic fontSize={["20px", "25px"]} mb={["20px", "25px"]}>
        <Text display={["none !important", "inline-block !important"]}>
          &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
        </Text>
        Expert Input
      </Text>
      <Flex flexDirection="row" justifyContent="space-between">
        <Div maxWidth="700px">
          {expertInput.map((person, index) => (
            <React.Fragment key={index}>
              <Text fontSize={["20px", "25px"]}>{person.name}</Text>
              <Text fontSize={["20px", "25px"]} pragmatic>
                {" "}
                {person.role}
              </Text>
              <br />
            </React.Fragment>
          ))}
        </Div>
        <Image
          display={["none", "inherit"]}
          src="/seiche_logo.svg"
          alt="Seiche Logo"
        />
      </Flex>
      <Text pragmatic fontSize={["20px", "25px"]} m="23px 0px">
        <Text display={["none !important", "inline-block !important"]}>
          &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
        </Text>
        Team
      </Text>
      <Flex flexDirection="row" justifyContent="space-between">
        <Div mb="23px">
          {team1.map((person, index) => (
            <React.Fragment key={index}>
              {person.link ? (
                <a
                  href={person.link}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <Text fontSize={["20px", "25px"]}>{person.name}</Text>
                </a>
              ) : (
                <Text fontSize={["20px", "25px"]}>{person.name}</Text>
              )}
              <Text fontSize={["20px", "25px"]} pragmatic>
                {" "}
                {person.role}
              </Text>
              <br />
            </React.Fragment>
          ))}
        </Div>
        <Image
          display={["none", "inherit"]}
          width="340px"
          src="/strelka_logo.svg"
          alt="Strelka Institute Logo"
        />
      </Flex>
      <Flex flexDirection="column">
        <Flex flexDirection="row" justifyContent="space-between">
          <Flex flexDirection="column">
            <Div mb="23px">
              {team2.map((person, index) => (
                <React.Fragment key={index}>
                  {person.link ? (
                    <a
                      href={person.link}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <Text fontSize={["20px", "25px"]}>{person.name}</Text>
                    </a>
                  ) : (
                    <Text fontSize={["20px", "25px"]}>{person.name}</Text>
                  )}
                  <Text fontSize={["20px", "25px"]} pragmatic>
                    {" "}
                    {person.role}
                  </Text>
                  <br />
                </React.Fragment>
              ))}
            </Div>
            <Div>
              {team3.map((person, index) => (
                <React.Fragment key={index}>
                  {person.link ? (
                    <a
                      href={person.link}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <Text fontSize={["20px", "25px"]}>{person.name}</Text>
                    </a>
                  ) : (
                    <Text fontSize={["20px", "25px"]}>{person.name}</Text>
                  )}
                  <Text fontSize={["20px", "25px"]} pragmatic>
                    {" "}
                    {person.role}
                  </Text>
                  <br />
                </React.Fragment>
              ))}
            </Div>
          </Flex>
          <Image
            display={["none", "inherit"]}
            width="239px"
            src="/trust_logo.svg"
            alt="Design Trust Logo"
          />
        </Flex>
        <Text fontSize={["20px", "25px"]} m="23px 0px" pragmatic>
          Seiche project was initially conceived during the 2018{" "}
          <a
            href="https://thenewnormal.strelka.com/publication-preview"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "inherit",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            The New Normal program
          </a>{" "}
          at the{" "}
          <a
            href="https://www.strelka.com/en/"
            target="_blank"
            rel="noreferrer"
            style={{
              color: "inherit",
              textDecoration: "none",
              fontWeight: "bold",
            }}
          >
            Strelka Institute for Media Architecture and Design
          </a>{" "}
          in Moscow
        </Text>
        <Flex>
          <Text fontSize={["20px", "25px"]} mr="5px">
            Program Director:
          </Text>
          <Text fontSize={["20px", "25px"]} pragmatic>
            Benjamin H. Bratton
          </Text>
        </Flex>
        <Flex>
          <Text fontSize={["20px", "25px"]} mr="5px">
            Design Faculty:{" "}
          </Text>
          <Text fontSize={["20px", "25px"]} pragmatic>
            Nicolay Boyadjiev, Maria Slavnova
          </Text>
        </Flex>

        <Image
          display={["inherit", "none"]}
          width="100%"
          mt="70px"
          src="/seiche_logo_small.svg"
          alt="Design Trust Logo"
        />
        <Image
          display={["inherit", "none"]}
          mt="55px"
          width="100%"
          src="/strelka_logo.svg"
          alt="Strelka Institute Logo"
        />
        <Image
          display={["inherit", "none"]}
          mt="55px"
          width="100%"
          src="/trust_logo_footer.svg"
          alt="Strelka Institute Logo"
        />
        <Text pragmatic fontSize={["20px", "25px"]} mt="23px">
          <a
            href="https://seiche.one/"
            target="_blank"
            rel="noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            www.seiche.one
          </a>
        </Text>
        <Text pragmatic fontSize={["20px", "25px"]}>
          <a
            href="mailto:seichedrive@gmail.com"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            seichedrive@gmail.com
          </a>
        </Text>
        <Text pragmatic fontSize={["20px", "25px"]}>
          <a
            href="https://twitter.com/seiche_strategy"
            target="_blank"
            rel="noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Twitter: @seiche_strategy
          </a>
        </Text>
        <Text pragmatic fontSize={["20px", "25px"]}>
          <a
            href="https://www.instagram.com/seiche_strategy/"
            target="_blank"
            rel="noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            Instagram: @seiche_strategy
          </a>
        </Text>
        <Text fontSize={["20px", "25px"]} m="23px 0px 0">
          The project is supported by Design Trust Seed Grant and Strelka
          Institute
        </Text>
      </Flex>
    </Flex>
  </Flex>
);

export default Footer;
