/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import {
  size,
  layout,
  position,
  color,
  border,
  background,
  space,
} from "styled-system";
import styled from "@emotion/styled";

const StyledImage = styled("img")(
  size,
  layout,
  position,
  color,
  border,
  background,
  space
);

export const Image = ({ ...rest }) => {
  return <StyledImage {...rest} />;
};
