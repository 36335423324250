import { createI18n } from 'react-router-i18n';

import translations from './i18n';

const locales = ['en', 'ch'];

export default createI18n(locales, translations, '');

export const getLocale = () => {
    const pathname = window.location.pathname;
    return pathname.split('/')[1] || 'en';
};
