import React from 'react';
import { Flex, Div } from "./index"

const Frame = ({ onToggle, link }) => {

    return (
        <Flex display={["none !important","flex !important"]} position="fixed" top='0' left='0' zIndex='10010' width='100%' height='100vh' backgroundColor="rgba(15, 15, 15, 0.75)">
            <Div width='calc(100% - 212px)' position='relative'  >
                <iframe
                    name='frame'
                    title="Strelka Mag Frame"
                    src={link}
                    width='100%'
                    height='100%'
                    frameBorder='0'
                    allowFullScreen
                />
            </Div>
            <Div width="212px" pointer onClick={onToggle}>
                <Div textAlign="center" marginTop="84px">
                    <img alt="close article" src="arrow.svg" />
                </Div>
            </Div>
        </Flex>
    );
};

export default Frame;