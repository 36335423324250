import React from 'react';
import { Flex, Div } from './Base'

export const Modal = ({ visible, onClose, videoSrc }) => {
    return (
        visible && (
        <Flex position="fixed" size="100%" backgroundColor="rgba(15, 15, 15, 0.75)" zIndex="999">
                <Flex justifyContent="center" width='calc(100% - 212px)' alignItems="center">
                    <Flex height="80%" width="100%" marginLeft="212px">
                        <iframe title="YouTube video" width="100%" height="100%"  src={videoSrc} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Flex>
                </Flex>
            <Div width="212px" >
                <Div textAlign="center" paddingTop="84px" >
                    <img alt="close article" src="arrow.svg" style={{cursor: 'pointer'}} onClick={onClose}/>
                </Div>
            </Div>
        </Flex>
        )
    );
};