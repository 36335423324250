import styled from "@emotion/styled";
import {
  flexbox,
  size,
  layout,
  position,
  color,
  border,
  shadow,
  background,
  space,
  typography,
} from "styled-system";
export const Div = styled("div")(typography,position,space, border, layout, shadow, color,  
    ({ pointer }) => ({
    ...(pointer && { cursor: "pointer" }),
  }));

export const Flex = styled(Div)(
  flexbox,
  position,
  shadow,
  background,
  size,
  space,
  {
    display: "flex",
  }
);
