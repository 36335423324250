import React, { useEffect, useCallback, useRef } from 'react';
import { Flex } from './Base'
import { Text } from './Text'
import { Button } from './Button'
import { Div } from './Box'
import styled from "@emotion/styled";
import useInView from "react-cool-inview";


const CardPic = styled(Div)(({ src, pos, ratio }) => ({
    position: 'absolute',
    backgroundImage: `url(${src})`,
    transform: 'translateX(-50%)',
    top: '-292px',
    left: '50%',
    right: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: `0 ${ratio  * (-pos)}px`,
    '@media screen and (max-width: 934px)': {
        transform: 'none',
        left: 0,
        top: 0,
        backgroundPosition: `center ${ratio  * (-pos)}px`,
    }
}))

export const Card = function({ onVideoToggle, onToggle, topText, background, name, title, leftText, rightText, locale, interviewLink, videoLink }) {
    const prevScrollPos = useRef(null);
    const [bgPos, _setBgPos] = React.useState(0);
    const [ratio, _setRatio] = React.useState(null);
    const bgPosRef = React.useRef(bgPos);
    const setBgPos = useCallback(data => {
      bgPosRef.current = data;
      _setBgPos(data);
    }, []);
    const ratioRef = React.useRef(bgPos);
    const setRatio = data => {
      ratioRef.current = data;
      _setRatio(data);
    };
    const animationHandler = useCallback(() => {
        window.requestAnimationFrame(() => {
            const { pageYOffset, innerHeight } = window
            const delta = pageYOffset - prevScrollPos.current
            if ((Math.abs(delta) < Math.round(innerHeight / 9))) return
            if (prevScrollPos.current && pageYOffset > prevScrollPos.current) {
                console.info("SCROLL UP", pageYOffset > prevScrollPos.current, prevScrollPos.current)
                bgPosRef.current < 8 && setBgPos(bgPosRef.current + 1)
            }
            else {
                console.info("SCROLL DOWN", bgPosRef.current)
                bgPosRef.current > 0 && setBgPos(bgPosRef.current - 1)
            }
            prevScrollPos.current = pageYOffset
        })
    }, [setBgPos])

    const handleRatioChange = useCallback(() => {
        setRatio(window.innerWidth > 934 ? 600 : 276)
    }, [])

    const addResizeListener = useCallback(() => {
        handleRatioChange()
        window.addEventListener("resize", handleRatioChange)
    },[handleRatioChange])

    const removeResizeListener = useCallback(()=>{
        window.removeEventListener("resize", handleRatioChange)
    }, [handleRatioChange])


    const addListener = useCallback(() => {
        window.addEventListener("scroll", animationHandler)
    },[animationHandler])

    const removeListener = useCallback(() => {
        window.removeEventListener("scroll", animationHandler)
    },[animationHandler])
    
    const { ref } = useInView(
        {
          threshold: 0.25, // Default is 0
          onEnter: () => {
              addListener()
          },
          onLeave: () => {
              removeListener()
          },
        }
      );

      useEffect(() => {
        addResizeListener()
        // setRatio(document.body.offsetWidth > 935 ? 928 : document.body.offsetWidth - 32)
        if (ref.current.getBoundingClientRect().bottom < 0) {
            setBgPos(0)
        }
        return () => removeResizeListener()
    }, [setBgPos, ref, addResizeListener,removeResizeListener])

    return (
        <Flex ref={window.innerWidth > 934 ? null : ref} mx={["16px","auto"]} flexDirection='column' position='relative' height="100%" wd='100%' minWidth={["auto","860px"]} minHeight={["auto","928px"]} mb={["72px", "300px"]}>
            <Div position={["relative"," absolute"]} width='100%' height={['276px', 'auto']} paddingTop={["0", "100%"]} zIndex='-1' overflow={['hidden', 'initial']} mb={['24px', '0']}>
                <CardPic ref={window.innerWidth > 934 ? ref : null} height={['2484px', '600px']} width={['100%', '1500px']} src={background} pos={bgPos} ratio={ratio} />
            </Div>
            <Flex maxWidth='860px' width='100%' height='100%' flexDirection='column' pdt={['0', '290px']}>
                <Text textAlign="center" pragmatic fontSize={["20px","25px"]} style={{wordBreak: "break-word"}} t>{topText}</Text>
                <Text mt={["10px","18px"]} textAlign="center" fontSize={["36px","75px"]}>
                    {name.toUpperCase()}:
                </Text>
                <Text textAlign="center" pragmatic fontSize={["36px","75px"]} t>{title}</Text>
                <Flex m={["24px 0px 0px","70px 34px 24px"]} justifyContent="space-between" alignItems="center" flexDirection={["column","row"]}>
                    <Flex mr={["auto","50px"]} mb={["16px", "inherit"]}>
                        <Text pragmatic fontSize={["20px","25px"]} t>
                            {leftText}
                        </Text>
                    </Flex>
                    <Flex minWidth={["auto","410px"]} mb={["24px", "inherit"]}>
                        <Text uppercase textAlign="right" fontSize={["20px","25px"]} fontWeight={[locale === 'ch' ? '600' : 'normal', locale === 'ch' ? '600' : 'normal']} t>
                            {rightText}
                        </Text>
                    </Flex>
                </Flex>
                <Flex mx={["0px","34px"]} flexDirection={["column", "row"]} justifyContent="space-between">
                    <Flex flex="1" mr={["auto","17px"]} width={["100%","auto"]} mb={["16px", "auto"]}>
                        <Button width="100%" onClick={onToggle}><Text fontSize={["20px","25px"]} pragmatic t>readInterview</Text></Button>
                    </Flex>
                    {videoLink && <Flex flex="1" ml={["auto","17px"]} width={["100%","auto"]}>
                        <Button width="100%" onClick={() => onVideoToggle(videoLink)}><Text fontSize={["20px","25px"]} pragmatic t>watchVideo</Text></Button>
                    </Flex>}
                </Flex>
            </Flex>
        </Flex>
    );
}

