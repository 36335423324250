import React from "react";

import styled from "@emotion/styled";
import {
  size,
  layout,
  position,
  color,
  border,
  typography,
  background,
  shadow,
  space,
} from "styled-system";

const StyledButton = styled("button")(
  size,
  layout,
  typography,
  shadow,
  position,
  color,
  border,
  background,
  space,
  {
      cursor: 'pointer',
      userSelect: 'none',
      outline: 'none'
  }
);

export const Button = ({ children, fontSize, disabled, ...rest }) => {
  return (
    <StyledButton
      fontFamily="Pragmatica,system-ui,sans-serif"
      padding="10px 25px"
      border="2px solid black"
      fontSize={fontSize || ["20px","25px"]}
      backgroundColor="white"
      {...rest}
    >
      {children}
    </StyledButton>
  );
};
