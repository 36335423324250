import styled from "@emotion/styled";
import { size, layout, position, color, border, space } from "styled-system";
export const Svg = styled("svg")(
  space,
  position,
  size,
  border,
  layout,
  color,
  ({ pointer }) => ({
    ...(pointer && { cursor: "pointer" }),
  })
);
