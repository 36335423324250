const translations =  {
  en: {
    subtitle: 'Conversations <span style="white-space: nowrap">on —> the</span><br />shifting role of sovereignty<br /> <— in an era of transnational<br />and extrastate<br />networks',
    description1: 'The most pressing issues we face today—from greenhouse gas emissions to deadly viruses—are planetary in scale and scope. Yet the failure of the international community to steer a safe path away from danger could not be any clearer. The pandemic exposed fatal shortcomings in the dominant mode of global organization, developed during thirty years of neoliberalism up to the 2008 financial crash. Since then, a window has emerged for new modes of intervention and societal recomposition, where economics and policy are built to fit the scale of our dilemmas. The existing model of global governance carries with it the imprint of Euro-Atlantic geopolitical culture.',
    description2: '&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;Elsewhere it is contested by China’s vision for a world order embodied in the Belt and Road Initiative, a megascale engineering project that will span many years into the future. While the neoliberal era aimed at dismantling state barriers to the movement of capital, the Belt and Road is focused on constructing networks for trade and domestic economic development, often by indebting partner nations. The technical, spatial, and temporal dynamics of these competing global models clearly differ, but what is their standing with respect to the planet—the underlying “thing” they intend to organize? Are there hints of an alternative model of planetary governance somewhere in the tension, which could rise to meet contemporary challenges? And most importantly: could the infrastructures already in place—physical, legal, and digital—be used for more innovative purposes, accommodating collective decisions about how we want the world to be remade?',
    card_bishop: {
      title: 'On Frictionless</br><— Sovereignty —>',
      topText: 'Professor of Global Arts and Politics at Winchester School of Art, University of Southampton',
      leftText: 'To begin, Ryan Bishop outlines the complex premises upon which sovereignty emerges. He proposes a concept of “frictionless sovereignty” as the means by which governance becomes a tool for fluid mobility. He argues that frictionlessness is a state both desired and enabled by decades of neoliberal economics and their attendant values: the free flow of goods, images, ideas, information, natural resources, raw materials, and people.',
      rightText: '“The desire to go back to an earlier form of governance has a particular appeal at the moment. But current international systems don’t allow for that boundedness to exist because borders are much more porous than people want to believe. They’re porous to pollution. They’re porous to, as we know, disease. We’re living through a moment that painfully reveals this condition.”'
    },
    card_slobodian: {
      title: 'A Century of Disappointment:<br />((((((( (( (((<br />Reappraising Neoliberalism',
      topText: 'Historian of Modern International History and Associate Professor at Wellesley College',
      leftText: 'While Bishop sees the synchronic interior of global trade as a pathological emergence, Quinn Slobodian debunks the widely held belief that neoliberal globalists planned to unite free market capitalism and democracy by designing a single world market without borders. A project of law and statecraft, the ideologues of The Geneva School did not in fact believe in self-regulating markets as autonomous entities, argues Slobodain. Nor did they see democracy and capitalism as synonymous—the reality was the inverse.',
      rightText: '“The neoliberal project was focused on designing institutions—not to liberate markets but to encase them, to inoculate capitalism against the threat of democracy.”',
    },
    card_mi: {
      title: '* Nomadic * Imaginaries<br /> * and * * Chinese * Globalization *',
      topText: 'Curator, Researcher, and Lecturer at the Academy of Media Arts, Cologne',
      leftText: 'The flow of goods, ideas, gifts, foods, and medicine reaches back through history. How may an understanding of the complex relations surrounding the ancient Silk Road inform our understanding of large-scale networks today? You Mi’s research looks at the Silk Roads as a complex figuration for anti-nationalist, decentralized, and nomadic imageries. She examines the viability of the Confutian cosmological system of tianxia (天下), meaning “all under heaven,” according to which the relations along the ancient Silk Road were organized, which has reemerged as the underlying principle for an alternative model for international relations.',
      rightText: '“Theoretically and symbolically tianxia is an unequal system, with China being at the top of the hierarchy. Yet in practice, the other polities would enjoy different degrees of material gains, from lavish gifts to trading along the tributary routes. In contrast, the European or the Westphalian model is based on, at least in theory, equality between states. But in practice, they were territorially expansionist and economically exploitative, which led to colonialism of European nation states.”',
    },
    card_ferchen: {
      title: 'Politics )—> of Chinese Infrastructure Loans',
      topText: 'Matt Frechen, Head of Global China Research at the Mercator Institute for China Studies',
      leftText: 'Accusations of “debt trap diplomacy” have been hurled around since 2017, but are China’s Belt and Road Initiative infrastructure loans a threat to the sovereignty of the nations involved? China analyst Matt Frechen believes it’s important to understand that the Belt and Road Initiative is a “two-way street,” and that everyone involved in the process—including host countries and stakeholders, but China as well—is responsible for assessing its potential risks, including when it comes to sustainability and national sovereignty.',
      rightText: '“China hopes to build political goodwill, but also to build political influence through deeper interdependence. Of course, Chinese government officials deny that there are any sort of geostrategic or political aims as part of the BRI, but I would say it’s basically assumed that there is a connection between economics and politics that runs pretty deep.”',
    },
    card_mok: {
      title: 'Many Belts, Many Roads:<br />— — — — — — — —<br />The Legal Precedent of BRI Contracts',
      topText: 'Legal Advisor on BRI Projects and Senior Associate at the Hong Kong office of Herbert Smith Freehills',
      leftText: 'The infrastructural developments of the Belt and Road Initiative involve more than sixty countries and a colossal ecosystem of corporations. The success of the project requires the removal of legal obstacles generated by different legal and judicial systems. Strelka Mag spoke to Murphy Mok about complex contracts, international arbitration, and the digital track of the BRI.',
      rightText: '“We expect to see a great increase in investments in the digital infrastructure space, not only along the Belt and Road countries, but globally.”',
    },
    about: 'ALL UNDER HEAVEN: Sоvereignty and Infrastructure After Globalization by Seiche is a design-research project that explores how waves of globalization have shaped and reshaped political, social, and economic paradigms over the past century, stretching and bending the meaning of sovereignty. In a series of conversations with experts in modern history, law, anthropology, and media studies, Seiche takes the Chinese Belt and Road Initiative (BRI) as a case study for understanding the physical and non-physical networks that are the backbone infrastructure of an emerging world order.',
    aboutTitle: 'ABOUT',
    readEssay: '<—  read the essay by Seiche  —>',
    readInterview: '<—  read full interview  —>',
    watchVideo: '<—  watch the video  —>',
  },
  ch: {
    subtitle: '關於在跨國網絡時代<br />下主權轉移的對話',
    description1: '如今，我們面臨的最緊迫的話題-從溫室氣體排放到致命的病毒-在規模和範圍上都是全球性的。儘管如此，國際社會在引導一條遠離危險之路上的失敗是再清晰不過了。病毒的大流行暴露了現主導的全球化模式的重大缺陷。這一模式是在2008年金融危機之前的新自由主義三十年中發展起來的。從那以後，一扇干預和重組社會的新窗口出現了，在此之中經濟和政策被重築以適應我們困境的規模。現存的全球治理模式帶有歐洲-太平洋地緣政治文化的烙印。除此之外，現有的全球秩序受到中國跨越多年的大規模工程一帶一路倡議的挑戰。新自由主義紀元打算消除資本移動的國境壁壘，而一帶一路側重於構建貿易和國內經濟發展網絡，這一過程通常是讓夥伴國負債。這些相互競爭的全球化模型的技術，空間和時間動態存在差異，但對於全球這一被組織的主體來講，這些模型有著什麼樣的地位？在這種競爭中的某處，是否暗示這存在可以適應現今挑戰的全球化管理替代模式？最重要的是，物理，法律和數字空間上已有的基礎設施能否被用於更加創新性的目的，來適應於我們如何重築世界的集體決定？',
    description2: '',
    card_bishop: {
      title: '<—論無摩擦主權 —>',
      topText: '南安普頓大學（University of Southampton）溫徹斯特藝術學院全球藝術和政治學教授',
      leftText: '首先，Ryan Bishop概述主權出現的複雜前提。他提出了“無摩擦主權”的概念，基於這個概念，政府成為了流體流動的手段。他強調，無摩擦是一種由數十年的新自由主義經濟及其伴隨價值所期望和實現的一種狀態。在這種狀態中，商品，圖像，思想，信息，自然資源，原材料和人員得以自由流動。',
      rightText: '“目前，回到早期統治形式的願望存在著一種特別的吸引力。但是現有的國際體系不允許這種約束性的存在，因為在邊界比人們想相信的有更多漏洞。他們容易被污染侵入，也容易被疾病侵入，正如我們所知的那樣。我們生活在一個在痛苦中揭示這種狀況的時刻。“'
    },
    card_slobodian: {
      title: '令人失望的世紀：<br />((((((( (( (((<br />重新評價新自由主義',
      topText: 'Historian of Modern International History and Associate Professor at Wellesley College',
      leftText: 'Quinn Slobodian, 現代國際史學家和韋爾斯利學院（Wellesley College）副教授<br />儘管Bishop視全球貿易的同步內部化為一種病態的呈現，Quinn Slobodian 駁斥了新自由主義全球化人士企圖通過設立一個無國界世界市場來統一自由市場資本主義和民主的普遍信念。Slobodain強調，作為法律和治國方略的項目，日內瓦學派的思想家們實際上並不相信自我管理的全球市場是自治的主體。事實上，他們也不把民主和資本主義視作同義詞——現實是相反的。',
      rightText: '“新自由主義計劃側重於設計機制，這一機制不是為了使市場自由化，而是包裹市場使資本主義免受民主的威脅。”',
    },
    card_mi: {
      title: '普天之下：遊牧映像和中國的全球化',
      topText: '博士，科隆媒體藝術學院（the Academy of Media Arts，Cologne）策展人，研究員和講師',
      leftText: '貨物，思想，禮品，食物和藥品的流動可以在歷史長河中追溯。對古代絲綢之路的複雜關係的理解如何給如今的大範圍關係網絡的理解帶來啟發？You Mi的研究將絲綢之路視為反民族主義，去中心化和遊牧映像的象徵。她確認了儒教宇宙學思想-天下-的有效性。天下的意思為“普天之下”，基於這個思想，古代絲綢之路的沿途關係得以構建。這一思想也重新成為國際關係替代模型的基本原則。',
      rightText: '“從理論和象徵意義上來說，天下是一個不平等體系。在這個體系中，中國處於登記制度的頂端。然而實際上，其他政治實體可以享有不同程度的物質回報，包括奢華的回贈和朝貢途中的貿易。與之相反的是，至少在理論上，歐洲或者威斯特伐利亞模式是基於國家間的平等關係的。但事實證明，這些國家是擴張性的和經濟掠奪性的，這導致了歐洲國家殖民主義的產生。”',
    },
    card_ferchen: {
      title: '中國基礎設施貸款的政治',
      topText: '墨卡托中國研究所（Mercator Institute for China Studies）全球中國研究主管',
      leftText: '“自從2017年以來，對於“債務陷阱外交”的指責就一直在進行，但中國的“一帶一路”倡議基礎設施貸款是否會威脅到參與國的國家主權？中國專家Matt Frechen認為，對一帶一路倡議的“雙向道”理解是十分重要的。這意味著參與這一進程的所有國家，包括東道國和利益相關者，應該肩負起對可持續性和國家主權的可能風險評估的責任，中國也應如此。',
      rightText: '“中國希望建立起政治善意，但同時也希望通過更深層次的相互依賴來提升政治影響力。當然，中國政府官員否認有任何作為一帶一路倡議一部分的地緣戰略和政治目標，但我要說的是，基本上可以認為在經濟和政治之間有著相當深遠的聯繫。”',
    },
    card_mok: {
      title: '多帶多路：<br />— — — — — — — —<br />一帶一路倡議的法定先例',
      topText: '一帶一路倡議（BRI）法律顧問和史密夫斐尔律師事務所（Herbet Smith Freehills）高級律師',
      leftText: '“一帶一路”倡議的基礎設施建設涉及了六十多個國家和一個巨大的企業生態系統。該計劃的成功需要克服由不同法律和司法系統所產生的法律障礙。Streika Mag和Murphy Mok就複雜的合同，國際仲裁和“一帶一路”倡議的數字軌跡展開了討論。',
      rightText: '“我們預計將看到在數字化基礎設施上的投資巨大增長，這一增長不僅是在一帶一路沿線國家，也將在全球範圍內發生。”',
    },
    about: '關於項目 Sieche的“世界之窗：地球危機時代的新興全球化”是一個研究全球化浪潮是如何在過去一個世紀塑造和重塑政治，社會，經濟的規範的設計研究項目，主權的意義已經被延伸和歪曲了。在和現代史、法律、人類學家、媒體學家的一系列對話中，Sieche把中國一帶一路作為研究案例，致力於理解在新興世界秩序中的作為主要基礎設施的物質和非物質網絡。',
    aboutTitle: '关于更多',
    readEssay: '<—  阅读文章  —>',
    readInterview: '<—  阅读采访  —>',
    watchVideo: '<—  观看影片  —>',
  }
}

export default translations;